export default {
  name: "retail-address-item",
  props: {
    showDel: {
      type: Boolean,
      default: true,
    },
    addressItem: {
      type: Object,
      default: () => {
        return {
          retailUserId: "retailUserId",
          name: "赵飞",
          phone: "17686679987",
          levelId: "经销商",
          juniorUserId: "retailUserId",
          address: "广东省，广州市",
          detailedAddress:
            "广东省广州市天河区测试地址广东省广州市海珠区珠江琶洲文化艺术中心海珠区珠江琶洲文化艺术中心",
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("click", this.addressItem);
    },
    onClickDel() {
      this.$emit("del", this.addressItem);
    },
    onClickEdit() {
      this.$emit("edit", this.addressItem);
    },
    toggle() {
      this.$emit("toggle", this.addressItem);
    },
  },
};
