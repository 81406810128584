import { debounce } from "lodash";
import { Dialog, Toast } from "vant";
import RetailAddressItem from "@/components/retail-order/retail-address-item/index.vue";
import {
  getRetailCustomerList,
  deleteRetailAddress,
} from "@/service/retail-order/index.js";
import { getToken } from "@/utils/localStorage.js";

export default {
  name: "retail-address-list-page",
  components: { RetailAddressItem },
  data() {
    return {
      choosedAddress: null, //  当前选中的地址
      type: "", //  当前发货对象
      customTypeMap: {
        juniorList: 0,
        retailList: 1,
      }, //  零售订单发货对象类型
      addressList: [], //  当前展示的地址
      customersMap: {
        retailList: [],
        juniorList: [],
      },
      keyword: "",
    };
  },
  watch: {
    keyword: {
      handler: debounce(function(newVal, oldVal) {
        if (newVal != oldVal) {
          this.addressList = this.customersMap[this.type].filter(
            (addressItem) => {
              return (
                addressItem.name.indexOf(newVal) >= 0 ||
                addressItem.phone.indexOf(newVal) >= 0
              );
            }
          );
        }
      }, 150),
    },
  },
  methods: {
    // 清空关键字
    onDeleteKeyword() {
      this.keyword = "";
      this.$refs["keyword"].focus();
    },
    //  跳转到提交订单页面
    onSubmit() {
      if (!this.choosedAddress) {
        Toast("必须选中一位顾客/代理才能发货～");
        return;
      }
      if (!this.choosedAddress.address) {
        Toast("先编辑代理地址才能下单～");
        return;
      }
      this.$store.commit("retail/setField", {
        addressInfo: this.choosedAddress,
      });
      this.$router.replace({
        name: "retail-preorder",
        query: { ...this.$route.query, type: this.type },
      });
    },
    //  添加顾客
    onAddCustomer() {
      this.$router.push({
        name: "address-edit",
        query: { type: "retail-add" },
      });
    },
    //  删除地址
    onDeleteAddress(addressItem) {
      const { choosedAddress } = this;
      if (
        choosedAddress &&
        choosedAddress.juniorUserId &&
        choosedAddress === addressItem.juniorUserId
      ) {
        this.choosedAddress = null;
      }
      if (
        choosedAddress &&
        choosedAddress.retailUserId &&
        choosedAddress.retailUserId === addressItem.retailUserId
      ) {
        this.choosedAddress = null;
      }
      Dialog.confirm({
        title: "删除地址",
        message: "确定删除地址？",
      })
        .then(() => {
          deleteRetailAddress({ retailId: addressItem.retailUserId }).then(
            (res) => {
              this.fetchData();
              Toast("删除成功～");
            }
          );
        })
        .catch(() => {});
    },
    //  编辑地址
    onEditAddress(addressItem) {
      const { juniorUserId, retailUserId, name, phone } = addressItem;
      const address = addressItem.address || ",";
      const [provinceCityRegion, detail] = address.split(",");
      this.$router.push({
        name: "address-edit",
        query: {
          retailType:this.type,
          type: "retail-update",
          name,
          phone,
          provinceCityRegion,
          detail,
          factorId: juniorUserId,
          id: retailUserId,
        },
      });
    },
    //  选中地址
    onToggle(choosedAddress) {
      this.choosedAddress = choosedAddress;
      const key = choosedAddress.retailUserId ? "retailUserId" : "juniorUserId";
      this.customersMap[this.type].forEach((address) => {
        this.$set(address, "choosed", false);
      });
      const cAddress = this.customersMap[this.type].find((address) => {
        return address[key] === choosedAddress[key];
      });
      if (cAddress) {
        this.$set(cAddress, "choosed", true);
      }
      this.$forceUpdate();
    },
    //  获取数据
    fetchData() {
      const { type, chooseAddressId } = this.$route.query;
      this.type = type;
      getRetailCustomerList({ token: getToken() }).then((res) => {
        this.customersMap = res || {};
        this.addressList = this.customersMap[this.type] || [];
        if (chooseAddressId) {
          const choosed = this.addressList.find(
            (item) =>
              item["retailUserId"] === chooseAddressId ||
              item["juniorUserId"] === chooseAddressId
          );
          this.$set(choosed, "choosed", true);
          this.$forceUpdate();
          this.choosedAddress = choosed;
        }
      });
    },
  },
  created() {
    this.fetchData();
  },
};
